.wrapper {
  padding: 10px 10px 19px 10px;
  background-color: #ededed;
}

.title {
  font-family: 'Exo 2';
  font-weight: 700;
  font-size: 17px;
  line-height: 20px;
  color: #494136;
}

.grid {
  margin: 20px 0;
  display: grid;
  gap: 5px;
}

.column {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2px;
}

.column div:last-child {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 5px;
  width: 92px;
}

.bank {
  font-family: 'Exo 2';
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #05141f;
  word-break: break-all;
  width: 142px;
}

.column div {
  padding: 5px;
  background-color: white;
}

.response {
  font-family: 'Exo 2';
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 20px;
}

.refresh {
  display: flex;
  align-items: center;
  column-gap: 12px;
  margin: auto;
  background-color: #d9bc92;
  font-family: 'Exo 2';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  padding: 12px 65px;
}
