.wrapper {
  background-color: white;
  padding: 30px 20px;
}

.grid {
  display: flex;
  flex-direction: column;
  row-gap: 2px;
}

.gridItem {
  padding: 5px 10px;
  border: 1px solid #d9d9d9;
}

.gridItem:first-child {
  flex: 0.6;
}

.gridItem:last-child {
  flex: 1;
  position: relative;
}

.labelIcon {
  color: red;
  padding-left: 20px;
  position: relative;
}

.labelIcon::before {
  content: '';
  background: url('../../assets/indicatorFill.svg');
  display: inline-block;
  position: absolute;
  left: 0;
  width: 17px;
  height: 17px;
}
