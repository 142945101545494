.confirmPaymentWrapper {
  background: #fff;
  padding: 50px;
  box-shadow: -5px 7px 32px 0px rgb(0 0 0 / 35%);
}

.confirmPaymentWrapper img {
  width: 150px;
  margin: 0 auto;
  display: block;
}
.confirmPaymentWrapper p {
  font-size: 24px;
  color: #585858;
  text-align: center;
}
