.wrapper {
  display: flex;
  flex-direction: column;
  column-gap: 4px;
  position: relative;
  padding-bottom: 50px;
}

.calendarHeader {
  position: absolute;
  /* z-index: 1; */
  top: -43px;
  display: flex;
  justify-content: space-between;
  width: 724px;
}

.leftArrow {
  flex: 1;
  cursor: pointer;
}

.leftArrow img {
  transform: rotate(90deg);
}

.rightArrow {
  flex: 1;
  transform: rotate(-180deg);
  cursor: pointer;
}

.rightArrow img {
  transform: rotate(90deg);
}

.calendarHeader p {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #05141f;
}

.grid div {
  word-break: break-all;
}

.grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 4px;
}

.day {
  background-color: #f2f2f2;
  text-align: center;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100px;
  min-height: 50px;
}

.time {
  display: grid;
  grid-template-rows: repeat(7, 1fr);
  row-gap: 4px;
}

.time div {
  text-align: center;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 100px;
  height: 50px;
  word-break: break-all;
}

.days {
  background-color: white;
  border: 1px solid #d9d9d9;
  cursor: pointer;
}

.days:hover {
  background-color: #f8f0e4;
  border: 1px dashed #d9bc92;
  position: relative;
}

.days:hover::after {
  content: 'Добавить запись';
  font-family: 'Exo 2';
  font-weight: 400;
  font-size: 10px;
  line-height: 20px;
  color: #d9bc92;
  position: absolute;
  right: 10%;
  top: 30%;
}

.record {
  text-align: center;
  font-size: 9px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  border: 1px solid #d9d9d9;
  overflow: hidden;
  text-overflow: ellipsis;
}

.record p:last-child {
  font-weight: 700;
}
