.button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  text-decoration-line: underline;
  color: #d9bc92;
	display: flex;
	align-items: center;
	column-gap: 12px;
	padding: 0;
}
