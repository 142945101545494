.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0;
}

.view {
  display: flex;
  align-items: center;
  column-gap: 15px;
}

.view p {
  font-size: 12px;
  line-height: 16px;
  color: #05141f;
}

.view p:last-child {
  color: #6a6a6a;
}

.view button,
.pages ul li {
  width: 36px;
  height: 36px;
  font-family: 'Exo 2';
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #05141f;
  background-color: transparent;
  cursor: pointer;
  border: none;
} 

.pages ul li {
  padding-top: 10px;
  /* cursor: default; */
  /* position: static;
  z-index: -100; */
}

.view span {
  color: #bababa;
}

.pages ul {
  display: flex;
  margin-right: 10px;
}

.pages ul li:first-child,
.pages ul li:last-child {
  width: 65px;
  font-family: 'Exo 2';
  font-size: 12px;
  line-height: 16px;
  color: #05141f;
  cursor: pointer;
  position: relative;
  background-color: '#D9BC92';
  z-index: 1;
}

.pages ul li:first-child::before {
  content: '';
  display: block;
  position: absolute;
  left: -5px;
  top: 15px;
  width: 10px;
  height: 10px;
  background: url('../../assets/arrow.svg') no-repeat;
  transform: rotate(90deg);
  z-index: 1;
}

.pages ul li:last-child::after {
  content: '';
  display: block;
  position: absolute;
  right: -8px;
  top: 15px;
  width: 10px;
  height: 10px;
  background: url('../../assets/arrow.svg') no-repeat;
  transform: rotate(-90deg);
  z-index: 1;
}

.page_active {
  background-color: #D9BC92 !important;
  color: #fff !important;
}
.paginationIndex li{
z-index: 0 !important;
}