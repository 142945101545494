.DealSteps {
  padding: 30px 20px;
  background-color: #f2f2f2;
}

.DealCreateForm {
  background-color: #fff;
  padding: 30px 20px;
  border: 1px solid #e0e0e0;
  position: relative;
}

.DealCreate {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  align-items: flex-start;
}

.DealStepsContainer {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  margin-bottom: 20px;
}
.DealStepsItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
.DealStepsItemNumber {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #cbcbcb;
  color: #fff;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 4px;
  position: relative;
  z-index: 1;
}
.DealStepsItem:not(:first-of-type):after {
  content: '';
  position: absolute;
  width: 100%;
  height: 0;
  border: 3px solid #cbcbcb;
  top: 17px;
  right: 50%;
  z-index: 0;
}
.DealStepsItem__active:after {
  border-color: #d9bc92 !important;
}
.DealStepsItemText {
  font-weight: 500;
  font-size: 12px;
  color: #bfbfbf;
  text-align: center;
}
.DealStepsItem__active .DealStepsItemNumber {
  background-color: #d9bc92;
}
.DealStepsItem__active .DealStepsItemText {
  color: #d9bc92;
}
.stepsRow {
  display: grid;
  grid-template-columns: 200px 1fr;
  gap: 10px 20px;
}
.stepsRow__name {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #6a6a6a;
}
.stepsRow__value {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #05141f;
}
