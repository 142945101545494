.background {
	min-height: 100vh;
  background: url("../../assets/images/background.jpg") 0 50%/cover no-repeat;
}

.background h1 {
  font-family: "Exo 2";
  font-weight: 400;
  font-size: 24px;
  line-height: 20px;
  color: #ffffff;
	padding: 60px 0 45px 200px;
}

.form {
  width: 20%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form .logo {
  margin-bottom: 70px;
}

.form div {
  background: rgba(255, 255, 255, 0.4);
  border: 1px solid white;
  padding: 0 15px;
}

.form form {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.form input {
  width: 100%;
  height: 50px;
  font-family: "Exo 2";
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: white;
  border: none;
  background-color: transparent;
}

.form input:focus {
  outline: 0;
}

.form input::placeholder {
  color: white;
}

.midInput {
  margin: 20px 0 20px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.icon {
	margin-top: 5px;
}

.form button {
  background-color: transparent;
  border: none;
}

.login {
  height: 50px;
  font-family: "Exo 2";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  color: #ffffff;
  background-color: #d9bc92 !important;
}