.wrapper {
  background-color: white;
  border: 1px solid #d9d9d9;
  cursor: pointer;
  padding: 5px;
}

/* .wrapper:hover {
  background-color: #f8f0e4;
  border: 1px dashed #d9bc92;
  position: relative;
} */

/* .days:hover::after {
  content: 'Добавить запись';
  font-family: 'Exo 2';
  font-weight: 400;
  font-size: 10px;
  line-height: 20px;
  color: #d9bc92;
  position: absolute;
  right: 10%;
  top: 30%;
} */

.day {
  font-family: 'Exo 2';
  font-weight: 500;
  font-size: 10px;
  line-height: 15px;
  text-align: right;
  color: #bababa;
}

.time {
  display: flex;
  justify-content: space-between;
  font-family: 'Exo 2';
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #000000;
}
