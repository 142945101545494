.table {
    border-spacing: 10px 10px;
    margin-top: 50px;
    margin-bottom: 20px;
}
.cell {
    padding: 10px;
    border: 1px solid #e7e7ed;
    font-size: 14px;
}
.right {
    display: flex;
    justify-content: flex-end;
}