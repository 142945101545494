header, .flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title {
  display: flex;
  align-items: center;
}

.title img {
  width: 44px;
  height: 44px;
  padding: 12px;
  background-color: #494136;
  margin-right: 10px;
}

header button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
	margin-left: auto;
	margin-right: 15px;
}
