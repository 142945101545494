.container {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.wrapper {
  width: 389px;
  height: 318px;
  padding: 30px 22px;
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
}

.wrapper img {
  transform: translate(80px);
  cursor: pointer;
}

.title {
  font-family: 'Exo 2';
  font-weight: 700;
  font-size: 20px;
  line-height: 20px;
  color: #494136;
}

.question {
  margin: 30px 0px;
  font-family: 'Exo 2';
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #000000;
}

.question span {
  color: #d9bc92;
}
