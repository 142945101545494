.wrapper {
  align-items: center;
  margin-top: 20px;
  background-color: #f8f0e4;
  padding: 10px;
}

.firstRow {
  display: flex;
}

.input {
  width: 348px;
  display: flex;
  align-items: center;
  column-gap: 10px;
  border: 1px solid #d9d9d9;
  padding: 0 14px;
  background-color: white;
  margin-left: 20px;
  margin-right: 5px;
}

.input input {
  flex: 1;
  border: none;
  outline: none;
  padding: 12px 0;
  font-size: 16px;
  line-height: 20px;
  color: #bababa;
}

.input input::placeholder {
  font-family: 'Exo 2';
  font-size: 16px;
  line-height: 20px;
  color: #bababa;
}

.advancedSearch {
  border: none;
  padding: 0;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #d9bc92;
  cursor: pointer;
  background-color: transparent;
  margin-left: auto;
  margin-right: 12px;
}

.advancedSearch p {
  margin-left: 11px;
}
