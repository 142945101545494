.input p {
  display: block;
  margin-bottom: 4px;
}

.input label {
  display: block;
  width: 100%;
  border: 1px dashed #d9bc92;
  padding: 11px 12px;
  background-color: #fbfbfb;
  outline: none;
  font-family: 'Exo 2';
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  text-decoration-line: underline;
  color: #d9bc92;
  cursor: pointer;
  position: relative;
}

.input input::placeholder {
  color: #bababa;
}

.file label {
  display: block;
  width: 100%;
  background-color: transparent;
  outline: none;
  font-family: 'Exo 2';
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #d9bc92;
  overflow: hidden;
  /* height: 25px;
  max-width: 260px; */
  text-overflow: ellipsis;
  word-break: normal;
}

.file {
  margin-top: auto;
  display: flex;
  column-gap: 10px;
  align-items: center;
  height: 44px;
  position: relative;
  z-index: 1;
}

.file p {
  width: 100%;
  font-family: 'Exo 2';
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #d9bc92;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px dashed #d9bc92;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.progress {
  height: 100%;
  background-color: #f8f0e4;
  position: absolute;
  z-index: -1;
}

.error {
  height: 100%;
  width: 100%;
  background-color: #fbfbfb;
  position: absolute;
  z-index: -1;
}

.container {
  display: flex;
  gap: 10px;
  align-items: center;
}

.deleteBtn {
  cursor: pointer;
}