.wrapper {
  min-width: 50%;
  max-width: 440px;
  background-color: #eb5757;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  padding: 24px 20px;
  word-break: break-all;
  z-index: 500;
}

.icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.icons img:last-child {
  cursor: pointer;
}

.message {
  font-family: 'Exo 2';
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: white;
  margin-top: 7px;
}
