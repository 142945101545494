.table {
  width: 100%;
  border: 1px solid #e7e7ed;
}

.table th {
  font-family: 'Exo 2';
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  color: black;
  text-align: left;
  vertical-align: middle;
  padding-left: 12px;
  border-bottom: 1px solid #e7e7ed;
  height: 48px;
  position: relative;
}

.table tr {
  cursor: pointer;
}

.table td {
  font-size: 13px;
  /* white-space: nowrap; */
  /* max-width: 40px; */
  /* overflow: hidden; */
  /* text-overflow: ellipsis; */
  line-height: 150%;
  color: #2e3135;
  padding-left: 12px;
  word-break: break-word;
  text-align: left;
  vertical-align: middle;
  height: 40px;
}

.table th div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 3px;
}

.table thead button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
}

.table td button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
}
.indication {
  width: 10px;
  height: 10px;
  display: flex;

  border-radius: 100px;
}
.clue {
  background: #fff;
  border: 1px solid #0000002b;
  padding: 5px;
  position: absolute;
  top: -35px;
  left: 0px;
}
.indicationWrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bottomBorder {
  border-bottom: 1px solid #e7e7ed;
}

.colEditModal {
  position: absolute;
  background: #fff;
  padding: 10px;
  right: 0;
  top: 115%;
  flex-direction: column;
  align-items: start !important;
  gap:10px;
  width: max-content;
  z-index: 99;
}
