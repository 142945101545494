.wrapper {
  display: flex;
  column-gap: 20px;
  background-color: white;
  flex: 1;
  overflow-y: auto;
  position: relative;
}

.closeModal {
  position: absolute;
  top: 0;
  right: 15px;
  cursor: pointer;
}

.photo {
  max-width: 256px;
  max-height: 256px;
  position: relative;
}

.photo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.delete {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.date {
  font-family: 'Exo 2';
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #05141f;
}

.photo p span {
  color: #6a6a6a;
}

.forms {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.forms section {
  padding: 0 20px;
  border: 1px solid #e0e0e0;
}
