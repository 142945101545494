.ChatPersonal {
  height: 100%;
  width: 480px;
  display: flex;
  flex-direction: column;
}
.ChatPersonal__header {
  padding: 18px 24px;
  border-bottom: 1px solid #d9bc92;
}

.ChatHistory {
  flex: 1;
  padding: 20px 24px;
}

.ChatInput {
  display: flex;
  gap: 30px;
  padding: 0 24px 30px 24px;
}
.ChatInput__wrapper {
  position: relative;
  display: flex;
  align-items: center;
  flex: 1;
}
.ChatInput__input {
  padding: 10px 12px;
  padding-right: 40px;
  gap: 8px;
  background: #fbfbfb;
  border: 1px solid #d9d9d9;
  width: 100%;
  font-weight: 400;
  font-size: 16px;
}
.ChatInput__send {
  position: absolute;
  right: 12px;
  cursor: pointer;
}

.ChatMessage {
  display: grid;
  grid-template-columns: 32px auto;
  gap: 16px;
}

.ChatMessage__message {
  display: flex;
  align-items: flex-end;
  gap: 20px;
  padding: 8px 10px;
  background: #fbfbfb;
  border: 1px solid #bababa;
  border-radius: 4px;
}
.ChatMessage__message p {
  font-weight: 400;
  line-height: 16px;
  font-size: 13px;
  color: #494136;
}
.ChatMessage__message span {
  font-weight: 600;
  font-size: 10px;
  text-transform: uppercase;
  color: #bababa;
  line-height: 16px;
}

.ChatDateLabel {
  display: flex;
  justify-content: center;
  margin-bottom: 25px;
}
.ChatDateLabel__label {
  padding: 6px 20px;
  background: #6a6a6a;
  border-radius: 40px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #ffffff;
}
