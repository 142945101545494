.button {
  display: flex;
  align-items: center;
  column-gap: 10px;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  padding: 12px 8px;
  color: #d9bc92;
  border: 1px solid #d9bc92;
  cursor: pointer;
  background-color: white;
}
