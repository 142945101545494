.fill {
  border: none;
  background-color: #d9bc92;
  color: white;
  padding: 12px 28px;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  cursor: pointer;
}

.button {
  background-color: transparent;
  border: none;
  color: #d9bc92;
  padding: 8px 28px;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  cursor: pointer;
}

.fill:disabled {
  cursor: default;
	background-color: #d9bb9290;
}
