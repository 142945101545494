.payments {
  width: 100%;
  max-height: 100% !important;
  min-height: 100vh;
  height: 100%;
  display: block;
  position: relative;
  background: linear-gradient(50deg, #cdc0ac, hsla(179, 54%, 76%, 1));
}

.cardTemplateWrapper {
  max-width: 440px;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
