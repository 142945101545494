.select {
  position: relative;
}

.select label {
  display: block;
  margin-bottom: 4px;
}

.input {
  border: 1px solid #d9d9d9;
  padding: 10px 12px;
  background-color: #fbfbfb;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.input input {
  border: none;
  outline: none;
  background-color: transparent;
  outline: none;
  font-family: 'Exo 2';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  display: block;
  width: 100%;
  cursor: pointer;
}

.input input::placeholder {
  color: #bababa;
}

.options {
  width: 100%;
  position: absolute;
  margin-top: 10px;
  cursor: pointer;
  z-index: 100;
}

.options ul {
  background-color: #fbfbfb;
  word-break: break-all;
}

.options ul li {
  padding: 8px 21px 8px 12px;
}

.options ul li:hover {
  background-color: #f2f2f2;
}
