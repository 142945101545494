.checkbox {
  position: relative;
}

.checkbox input {
  position: absolute;
  z-index: -1;
  opacity: 0;
  margin: 0;
  top: 0;
}

.checkbox input + label {
  user-select: none;
  font-weight: 400;
  font-size: 14px;
  color: #05141f;
  display: flex;
  align-items: center;
  column-gap: 8px;
}

.checkbox input + label::before {
  content: '';
  display: block;
  width: 16px;
  height: 16px;
  background-repeat: no-repeat;
  background-position: center;
  border: 2px solid #d9d9d9;
  border-radius: 3px;
  cursor: pointer;
}

.checkbox input:checked + label::before {
  border-color: #d9bc92;
  background-color: #d9bc92;
  background-image: url('../../../assets/input/check.svg');
}
