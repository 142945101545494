.input {
  position: relative;
}

.input label {
  display: block;
  margin-bottom: 4px;
}

.input input {
  display: block;
  width: 100%;
  border: 1px solid #d9d9d9;
  padding: 11px 12px;
  background-color: #fbfbfb;
  outline: none;
  font-family: 'Exo 2';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #05141f;
}

.input input::placeholder {
  color: #bababa;
}

.input input:focus {
  border: 1px solid #d9bc92;
}

.input input:disabled {
  background-color: #ededed;
}

.input img {
  position: absolute;
  top: 50%;
  right: 12px;
}
