.react-datepicker {
  border: none;
  font-family: 'Exo 2';
  padding-left: 4px;
  padding-right: 4px;
}

.react-datepicker__header {
  background-color: white;
  border-bottom: none;
}

.react-datepicker__day-names {
  background-color: #f8f0e4;
  text-transform: uppercase;
}

.react-datepicker__day--selected {
  border-radius: 0;
  background-color: #d9bc92;
}

.react-datepicker__day--selected:hover {
  border-radius: 0;
  background-color: #d9bc92;
}

.react-datepicker__day:hover {
  border-radius: 0;
}

.react-datepicker__day--keyboard-selected {
  background-color: white;
  color: black;
}
.react-datepicker__current-month {
  font-weight: 500;
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: 15px;
	text-transform: capitalize;
}

.react-datepicker__week {
  margin-top: 10px;
}

.react-datepicker__navigation {
  top: 10px;
}

.react-datepicker__day--outside-month {
  color: rgba(128, 128, 128, 0.483);
}

.react-datepicker__day {
  padding: 2px 3px;
}

.react-datepicker__navigation--next {
  background: url('../../../assets/arrow.svg') no-repeat;
  transform: rotate(270deg);
  top: -1px;
}

.react-datepicker__navigation--previous {
  background: url('../../../assets/arrow.svg') no-repeat;
  transform: rotate(90deg);
  top: 20px;
}

.react-datepicker__navigation-icon--next::before,
.react-datepicker__navigation-icon--previous::before {
  opacity: 0;
}