.credit {
    margin-bottom: 15px;
    margin-top: 15px;
    border: 1px solid #D9D9D9;
    padding: 30px 20px;
}
.credit h3 {
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 20px;
}
.credit__main_info {
    margin-bottom: 15px;
}
.bank {
    border: 1px solid #D9D9D9;
    max-width: 700px;
    padding: 16px;
    margin-bottom: 15px;
}
.bank__row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px
}
.bank img {
    max-width: 150px;
    height: 70px;
    object-fit: contain;
    margin: 0 auto;
}
.bank__img {
    width: 200px;
    display: flex;
    justify-content: center;
}

.title {
    font-family: 'Exo 2';
    font-style: normal;
    font-weight: 700 !important;
    font-size: 20px !important;
    line-height: 20px !important;
    color: #494136;
}
.bank_name {
    font-family: 'Exo 2';
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: #494136;
}

.status {
    font-weight: 600;
    font-size: 10px;
    line-height: 150%;
    text-align: center;
    text-transform: uppercase;
    color: #fff;
    background-color: #BABABA;
    width: 140px;
    border-radius: 2px;
    padding: 4px 0;
    margin-left: 10px;
}

.status__rejected {
    background-color: #EE7171;
}
.status__accepted {
    background-color: #71B183;
}
.bank__info {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
}
.bank__info_title {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #05141F;
}
.bank__info_sub {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #6A6A6A;
}
.sub {
    font-weight: 400;font-size: 12px;line-height: 14px;color: #6A6A6A;
    margin-bottom: 15px;
}