.wrapper {
  margin-top: 20px;
  display: flex;
  column-gap: 20px;
  padding-bottom: 20px;
}

.forms {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.forms section {
  background-color: white;
  padding: 0 20px;
  border: 1px solid #e0e0e0;
}

.forms section:nth-child(2) {
  padding-bottom: 20px;
}

.newClientTabs {
  background: #f8f0e4;
  padding: 10px;
  margin-top: 38px;
}

.newClientTabsButton {
  color: #d9bc92;
  background: none;
  border: none;
  padding: 12px;
  cursor: pointer;
}

.newClientDataWrapper {
  margin-top: 20px;
  display: grid;
  grid-template-columns: 0.22fr 1fr;
}

.newClientDataPhoto {
  cursor: pointer;
  margin-right: 20px;
}
.newClientDataPhoto label input {
  display: none;
}
.newClientDataPhoto label {
  cursor: pointer;
  text-align: center;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23BABABAFF' stroke-width='2' stroke-dasharray='6%2c 14' stroke-dashoffset='84' stroke-linecap='square'/%3e%3c/svg%3e");
  padding: 18px 15px 38px 15px;
  display: block;
}
.newClientDataPhoto label span {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #05141f;
  margin-top: 8px;
  display: block;
}
.newClientDataPhoto label p {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  text-decoration-line: underline;
  color: #bababa;
}
.newClientDataForm {
  background: #fff;
  border: 1px solid #d9d9d9;
  padding: 30px 20px;
}
.newClientDataForm h2 {
  font-weight: 700;
  font-size: 20px;
  color: #494136;
}
