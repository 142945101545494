.client {
  font-family: 'Exo 2';
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 20px;
  text-decoration-line: underline;
  color: #d9bc92;
	cursor: pointer;
}
