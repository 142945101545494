.file {
  display: flex;
  align-items: center;
  column-gap: 10px;
  height: 44px;
  margin-top: auto;
}

.file span {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #d9bc92;
}
