.wrapper {
  position: absolute;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
  background-color: red;
  right: 50px;
  z-index: 100;
  top: 0;
  width: 200px;
}

.wrapper p {
  font-family: 'Exo 2';
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
}

.row {
  display: flex;
  align-items: 'center';
  column-gap: 10px;
  padding: 12px 10px;
  cursor: pointer;
  margin-bottom: 1px;
  background-color: white;
}

.row:hover {
  background-color: #f2f2f2;
}

.row:last-child {
  margin-bottom: 0;
}
