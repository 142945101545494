.ChatUser {
  display: flex;
  gap: 14px;
  align-items: center;
}
.ChatUser__avatar {
  width: 45px;
  height: 45px;
  background: #494136;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ChatUser__wrapper {
}
.ChatUser__name {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #05141f;
}
.ChatUser__last_message {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #bababa;
}
