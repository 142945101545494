.sort {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #6a6a6a;
}

.column {
  display: flex;
  align-items: center;
  column-gap: 5px;
}

.column span {
  color: #05141f;
  cursor: pointer;
}
