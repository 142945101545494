.user {
  display: flex;
  align-items: center;
	column-gap: 10px;
}

.name p:first-child {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #05141f;
}

.name p:last-child {
  font-size: 10px;
  line-height: 12px;
  text-align: right;
  text-transform: uppercase;
  color: #bababa;
}

.avatar {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  object-fit: cover;
  background-color: #bababa;
  display: flex;
  align-items: center;
  justify-content: center;
}
