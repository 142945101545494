.sidebar {
  min-width: 316px;
  padding: 80px 36px 36px 36px;
  background-color: #494136;
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 15px;
}

.logo {
  margin-bottom: 50px;
}

.sidebar hr {
  border: 1px solid transparent;
  background-color: rgba(255, 255, 255, 0.3);
  margin: 20px 0;
}

.exit {
  border: none;
  padding: 0;
  background-color: transparent;
  color: #a4a09a;
  font-family: 'Exo 2';
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  height: 40px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  cursor: pointer;
}

.exit::before {
  content: '';
  display: block;
  width: 19px;
  height: 20px;
  background: url('../../assets/sidebar/exit.svg');
  margin-right: 14px;
}

a:link,
a:visited,
a:hover,
a:active {
  text-decoration: none;
}
