.borderWrapper {
  padding: 30px 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: white;
  border: 1px solid #e0e0e0;
}

.wrapper {
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: white;
}

.wrapper form,
.borderWrapper form {
  margin-top: 20px;
  margin-bottom: 20px;
}

.header {
  display: flex;
  align-items: 'center';
  justify-content: space-between;
}

.back {
  transform: scale(1.5) rotate(90deg);
  position: absolute;
  left: 10px;
  top: 15px;
}
