.heading {
    position: relative;
}
.close {
  /* position: absolute; */
  right: 0;
  top: 2px;
  cursor: pointer;
}
.title {
    text-align: center;
    margin-bottom: 30px;
}
.wrapper {
  /* height: 100%; */
}