.customInput input {
  background: #fbfbfb;
  border: 1px solid #d9d9d9;
  padding: 12px;
  outline: none;

  width: 100%;
}
.customInput input::placeholder {
  font-weight: 400;
  font-size: 16px;
  color: #bababa;
}
.customInput p {
  margin-bottom: 8px;
  font-weight: 500;
  font-size: 16px;

  color: #05141f;
}

.customInput input:disabled {
  background-color: #ededed;
}
