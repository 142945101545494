.wrapper {
  padding: 30px 20px;
  background-color: white;
  border: 1px solid #e0e0e0;
  margin-top: 20px;
}

.status {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  margin-top: 30px;
}

.modal {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 999;
  display: flex;
}
