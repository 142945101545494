.photo {
  max-width: 256px;
  max-height: 256px;
  position: relative;
  cursor: pointer;
  background-color: #f4f4f4;
}

.selectedImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.photo label input {
  display: none;
}

.photo label {
  cursor: pointer;
  text-align: center;
}

.label {
  padding: 18px 15px 38px 15px;
  display: block;
}

.photo label span {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #05141f;
  display: block;
}

.photo label p {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  text-decoration-line: underline;
  color: #bababa;
	margin: 15px 0;
}

.delete {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}
