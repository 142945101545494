.container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
	z-index: 100;
}

.wrapper {
  padding: 30px 20px;
  background-color: white;
  border: 1px solid #e0e0e0;
  margin: 30px 20px;
  flex: 1;
  overflow-y: auto;
}

.wrapper h1 {
  font-family: 'Exo 2';
  font-weight: 700;
  font-size: 22px;
  line-height: 20px;
  color: #494136;
}

.wrapper img {
  cursor: pointer;
}

.wrapper section {
  margin-top: 40px;
}

.actions {
  margin-top: 40px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}
