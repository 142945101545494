.Toastify__toast {
    background-color: #8EC0D6;
    color: #fff;
    padding: 24px 20px;
    word-break: break-all;
    z-index: 500;
}
.Toastify__toast-body {
    flex-direction: column;
    gap: 15px;
}
.Toastify__close-button--light {
    color: #fff;
}

.Toastify__toast--error {
    background-color: #eb5757;
}
.hover-lighten {
    transition: opacity 0.2s ease-in-out;
}
.hover-lighten:hover {
    opacity: 0.5;
}
.hover-lighten:active {
    opacity: 0.1;
}