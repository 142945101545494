.ChatList {
  border-right: 3px solid #f8f0e4;
}

.ChatListItem {
  padding: 10px 24px;
  border-width: 1px 0;
  border-style: solid;
  border-color: #f8f0e4;
  transition: all 0.3s ease;
  cursor: pointer;
  margin-bottom: 1px;
  display: flex;
}
.ChatListItem__wrapper {
  flex: 1;
}
.ChatListItem:hover,
.ChatListItem_active {
  background-color: #f8f0e4;
}

.ChatListFilter {
  padding: 20px 24px;
  display: flex;
}
.ChatListFilter__item {
  padding: 0 5px;
  cursor: pointer;
  color: #6a6a6a;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  transition: all 0.1s ease;
}
.ChatListFilter__item:hover,
.ChatListFilter__item_active {
  text-shadow: 0 0 0.04px #d9bc92;
  font-size: 16px;
  line-height: 20px;
  color: #d9bc92;
  text-decoration: underline;
}
