.table {
    margin-bottom: 40px;
}
.table tr, .table td, .table th {
  border-bottom: 1px solid #dee2e6;
  padding: 12px 30px 12px 0;
  vertical-align: top;
  min-width: 150px;
  align-items: center;
}

.table th {
    font-weight: 600;
}

.link:hover {
    text-decoration: underline;
    cursor: pointer;
}