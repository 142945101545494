.cardTemplate {
  position: relative;
  background: #fff;
  padding: 120px 20px 30px 20px;
  width: 100%;
  border-radius: 8px;
  box-shadow: -5px 7px 32px 0px rgb(0 0 0 / 35%);
}

.cardInput {
  padding: 10px 10px 8px 10px;
  font-size: 24px;
  max-width: 100%;
  width: 100%;
  color: #585858;

  border-radius: 8px;
  border: 1px solid #d4d4d4;
}
.cardInput::placeholder {
  font-size: 24px;
  color: #585858;
}

.cardTemplateItem {
  margin-bottom: 24px;
}
.cardTemplateItemTitle {
  font-size: 16px;
  text-transform: uppercase;
  color: #585858;
  margin-bottom: 14px;
}

.mockCard {
  background: linear-gradient(50deg, #a2a2a2, hsla(17, 54%, 76%, 1));
  border: 0.5px solid #d4d4d4;
  padding: 30px;
  border-radius: 30px;
  height: 200px;
  position: absolute;
  width: 90%;
  left: 50%;
  top: -110px;
  transform: translate(-50%, 0px);
}
.mockCardItem {
  position: relative;
  z-index: 1;
  border-bottom: 1px solid white;
  padding: 5px;
  color: #585858;
  font-size: 20px;
  background: #ffffff1c;
  margin-top: 44px;
}
.cardButton {
  border: 1px solid #d4d4d4;
  background: #c9ada2;
  font-size: 18px;
  color: #ffffff;
  padding: 16px 20px;
  width: 100%;
  border-radius: 8px;
}
