.item {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 10px;
}

.record {
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 10px;
  padding-left: 54px;
}

.title {
  display: flex;
  align-items: center;
  row-gap: 12px;
}

.item:last-child {
  margin-bottom: 0;
}

.icon {
  margin-right: 14px;
}

.countContainer {
  display: flex;
  align-items: center;
}

.circle {
  font-family: "Exo 2";
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: white;
  margin-left: 10px;
  background: #f9774e;
  border-radius: 100px;
  padding: 2px 10px;
}